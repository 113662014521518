import { useState, useEffect } from "react";
import Image from "react-bootstrap/Image";

type GalleryProps = {
    items: { small: string, big: string }[],
};

export default function Gallery({ items }: GalleryProps): JSX.Element {

    const [ showIndex, setShowIndex ] = useState<null | number>(null);

    useEffect(() => {
        function keyHandler(e: KeyboardEvent) {
            switch (e.code) {
                case "Escape":
                    setShowIndex(null);
                    return;
                case "ArrowLeft":
                    setShowIndex(showIndex => {
                        if (showIndex === null) return null;
                        return showIndex - 1 < 0 ? items.length - 1 : showIndex - 1;
                    });
                    return;
                case "ArrowRight":
                    setShowIndex(showIndex => {
                        if (showIndex === null) return null;
                        return showIndex + 1 >= items.length ? 0 : showIndex + 1;
                    });
                    return;
            }
        }
        window.addEventListener("keydown", keyHandler);

        return () => {
            window.removeEventListener("keydown", keyHandler);
        };
    }, [ items.length ]);

    return <div className="gallery">
        {items.map((item, i) => <div className="item" key={i} onClick={() => setShowIndex(i)}>
            <Image src={item.small} thumbnail />
        </div>)}
        {showIndex !== null && items[showIndex] && <div className="gallery-show">
            <div className="close" onClick={() => setShowIndex(null)}>
                <span className="mdi mdi-close" />
            </div>
            <div className="arrow" onClick={() => setShowIndex(showIndex => {
                if (showIndex === null) return null;
                return showIndex - 1 < 0 ? items.length - 1 : showIndex - 1;
            })}>
                <span className="carousel-control-prev-icon" />
            </div>
            <div className="gallery-big" style={{ backgroundImage: `url("${items[showIndex].big}")` }}></div>
            <div className="arrow" onClick={() => setShowIndex(showIndex => {
                if (showIndex === null) return null;
                return showIndex + 1 >= items.length ? 0 : showIndex + 1;
            })}>
                <span className="carousel-control-next-icon" />
            </div>
        </div>}
    </div>;
}
