import { useTitle } from "../components";

export default function PricePage(): JSX.Element {
    useTitle("Ceník");

    return <>
        <h1>Ceník</h1>
        <ul>
            <li>Letní sukně 850,-</li>
            <li>Letní sportovní sukně 640,-</li>
            <li>Šatovka 1420,-</li>
            <li>Šaty podzim až jaro 1740,-</li>
            <li>Šaty {"\"netopýr\""} 1550,-</li>
            <li>
                Dětské
                <ul>
                    <li>Šatičky 740,-</li>
                    <li>Šatičky zip 950,-</li>
                    <li>
                    Softshell
                        <ul>
                            <li>Bunda 720,-</li>
                            <li>Kalhoty 550,-</li>
                            <li>Čepice 230,-</li>
                            <li>Rukavice 180,-</li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>

        <p>Všechny ceny jsou uvedeny včetně DPH.</p>
        <p>
            Zboží si můžete vyzvednout v kamenném obchodě ve Vrchlabí, případně Vám ho zašleme Českou poštou.
            Dle domluvy i možnost vyzvednutí zboží v Hradci Králové a Třebechovicích pod Orebem.
        </p>

    </>;
}
