import { Component, ReactNode } from "react";

export default class ErrorBoundary extends Component<{}, { hasError: boolean }> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): { hasError: boolean } {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error: Error, errorInfo: unknown): void {
        // You can also log the error to an error reporting service
        if (process.env.NODE_ENV === "development")
            console.error(error, errorInfo);
    }

    render(): ReactNode {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div className="error">
                <h1>Something went wrong.</h1>
                <p>Click <a href="/">here</a> for refresh website.</p>
            </div>;
        }
        return this.props.children;
    }
}
