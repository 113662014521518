import { Link } from "react-router-dom";

export default function Nav(): JSX.Element {
    return <nav>
        <Link to="/o-nas">O nás</Link>
        <Link to="/">Sortiment</Link>
        <Link to="/motivy">Motivy</Link>
        <Link to="/cenik">Ceník</Link>
        <Link to="/kontakt">Kontakt</Link>
    </nav>;
}
