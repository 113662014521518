import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Routes from "./Routes";

export default function App(): JSX.Element {
    return <BrowserRouter>
        <Layout>
            <Routes />
        </Layout>
    </BrowserRouter>;
}
