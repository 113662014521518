import { useTitle } from "../components";
import { Spinner } from "react-bootstrap";

import { useApi } from "../services/api";

import Gallery from "../components/Gallery";

export default function AboutUsPage(): JSX.Element {
    useTitle("O nás");

    const [ photos, , loading ] = useApi.getAboutUs({});

    return <>
        <h1>O nás</h1>
        <p>
            V roce 1994 založila Vlasta Paulů (Karmášková) ve Vrchlabí malý obchůdek s textilem zabývající se
            prodejem a vlastní výrobou oblečení z přírodních materiálů. Textil u Karmáška je tu stále pro Vás.
        </p>
        <p>
            Karmis je prémiová značka dámského oblečení, kterou pokračuje její dcera Jana Bezoušková Paulů
            v rodinné tradici.  Používáme přírodní materiály české výroby. Navrhujeme si vlastní střihy tak,
            aby Vám naše oblečení co nejlépe sedělo. Ušité výrobky ručně barvíme, batikujeme, leptáme, savujeme,
            vzorkujeme, ustalujeme a pereme. Na vyrobené kusy ručně malujeme, čímž se každý kus stává naprosto
            originálním. Když je to v našich silách, jsme schopni malovat i na objednávku.
        </p>

        {loading && <Spinner animation="border" variant="success" />}

        {photos && <Gallery items={photos.map(photo => ({
            small: `${photo.url}.small.jpeg`,
            big: `${photo.url}`,
        }))} />}
    </>;
}
