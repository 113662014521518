import { useTitle } from "../components";

export default function ContactPage(): JSX.Element {
    useTitle("Kontakt");

    return <>
        <h1>Kontakt</h1>

        <h2><span className="mdi mdi-account"></span>Jana Bezoušková Paulů</h2>
        <p>objednávky Třebechovice pod Orebem</p>
        <p><span className="mdi mdi-phone"></span><a href="tel:+420731102910">+420 731 102 910</a></p>

        <h2><span className="mdi mdi-account"></span>Šárka Fialová</h2>
        <p>objednávky K-móda Vrchlabí</p>

        <h2><span className="mdi mdi-at"></span>E-mail</h2>
        <p><a href="mailto:info@karmis.cz">info@karmis.cz</a></p>

        <h2><span className="mdi mdi-map-marker"></span>Kamenný obchod</h2>
        <p>Textil u Karmáška, Horská 2, Vrchlabí</p>

        <div className="flex-row">
            {/* <img src="" alt="K-móda kamenný obchod" /> */}
            {/* eslint-disable-next-line max-len */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2530.6685134738113!2d15.6069804!3d50.6332745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470eeaad22166f67%3A0x733d6f88c2aa5fa!2sK-moda+s.r.o.!5e0!3m2!1scs!2scz!4v1442224200944" style={{ border: 0, flexGrow: 1 }} allowFullScreen width="100%" height="400" frameBorder="0"></iframe>
        </div>
    </>;
}
