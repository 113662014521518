import { useTitle } from "../components";
import { Spinner } from "react-bootstrap";

import { useApi } from "../services/api";

import Gallery from "../components/Gallery";

export default function MotivesPage(): JSX.Element {
    useTitle("Motivy");

    const [ photos, , loading ] = useApi.getMotives({});

    return <>
        <h1>Motivy</h1>
        <p>
            Naše modely ručně malujeme různými motivy a tím se každý kus stává naprosto originálním.
            Pokud je to v našich silách, jsme schopni udělat motiv na přání případně změnit místo malby.
            Zde je malá ochutnávka naší práce.
        </p>

        {loading && <Spinner animation="border" variant="success" />}

        {photos && <Gallery items={photos.map(photo => ({
            small: `${photo.url}.small.jpeg`,
            big: `${photo.url}`,
        }))} />}
    </>;
}
