import { Switch, Route } from "react-router-dom";

import SortimentPage from "./pages/Sortiment";
import AboutUsPage from "./pages/AboutUs";
import MotivesPage from "./pages/Motives";
import PricePage from "./pages/Price";
import ContactPage from "./pages/Contact";
import Error404 from "./pages/404";

export default function Routes(): JSX.Element {
    return <Switch>
        <Route path="/" exact component={SortimentPage} />
        <Route path="/o-nas" exact component={AboutUsPage} />
        <Route path="/motivy" exact component={MotivesPage} />
        <Route path="/cenik" exact component={PricePage} />
        <Route path="/kontakt" exact component={ContactPage} />
        <Route path="*" component={Error404} />
    </Switch>;
}
