import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";

import { useMemo, useState } from "react";
import { Spinner, Button, ButtonGroup } from "react-bootstrap";

import { useTitle } from "../components";

import { useApi } from "../services/api";

import Gallery from "../components/Gallery";

import { Type, Color } from "../types";

export default function SortimentPage(): JSX.Element {
    useTitle("Sortiment");

    const [ photosHeader ] = useApi.getPhotosHeader({});
    const sortedHeaders = useMemo(() => shuffle(photosHeader || []), [ photosHeader ]);

    const [ type, setType ] = useState<Type>("T");
    const [ color, setColor ] = useState<Color>("A");

    const [ photos, , loading ] = useApi.getPhotosFiltered({ type, color });

    return <>
        {photosHeader !== null && <Carousel interval={2500}>
            {sortedHeaders.map(photo => <Carousel.Item key={photo.url}>
                <img
                    className="d-block w-100"
                    src={photo.url}
                    alt="First slide"
                />
            </Carousel.Item>)}
        </Carousel>}

        <br />
        <h1>Sortiment</h1>

        <ButtonGroup style={{ marginBottom: "16px", display: "block" }}>
            <Button variant="outline-secondary" active={type === "S"} onClick={() => {
                setType("S");
                setColor("A");
            }}>Sukně</Button>
            <Button variant="outline-secondary" active={type === "T"} onClick={() => {
                setType("T");
                setColor("A");
            }}>Šatovky</Button>
            <Button variant="outline-secondary" active={type === "P"} onClick={() => {
                setType("P");
                setColor("A");
            }}>Šaty {"\"netopýr\""}</Button>
            <Button variant="outline-secondary" active={type === "J"} onClick={() => {
                setType("J");
                setColor("A");
            }}>Šaty podzim až jaro</Button>
            <Button variant="outline-secondary" active={type === "D"} onClick={() => {
                setType("D");
                setColor("I");
            }}>Dětské</Button>
        </ButtonGroup>

        {(type === "S" || type === "T") && <ButtonGroup>
            <Button variant="outline-secondary" active={color === "A"} onClick={() => setColor("A")}>
                    Všechny
            </Button>
            <Button variant="outline-secondary" active={color === "B"} onClick={() => setColor("B")}>Modrá</Button>
            <Button variant="outline-secondary" active={color === "C"} onClick={() => setColor("C")}>Bordó</Button>
            <Button variant="outline-secondary" active={color === "T"} onClick={() => setColor("T")}>
                    Tyrkys-fialová
            </Button>
            {type === "T" && <Button
                variant="outline-secondary"
                active={color === "Y"}
                onClick={() => setColor("Y")}
            >Žlutá</Button>}
            {type === "S" && <Button
                variant="outline-secondary"
                active={color === "S"}
                onClick={() => setColor("S")}
            >
                    Sportovní
            </Button>}
            <Button variant="outline-secondary" active={color === "O"} onClick={() => setColor("O")}>
                    Žluto-oranžová
            </Button>
        </ButtonGroup>}

        {type === "D" && <ButtonGroup>
            <Button variant="outline-secondary" active={color === "I"} onClick={() => setColor("I")}>Šatičky</Button>
            <Button variant="outline-secondary" active={color === "F"} onClick={() => setColor("F")}>Softshell</Button>
        </ButtonGroup>}

        {loading && <><br /><Spinner animation="border" variant="success" /></>}

        {photos && photos.length === 0 && <div className="info">Žádné fotografie k zobrazení</div>}

        {photos && <Gallery items={photos.map(photo => ({
            small: `${photo.url}.small.jpeg`,
            big: `${photo.url}`,
        }))} />}
    </>;
}

function shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [ array[currentIndex], array[randomIndex] ] = [
            array[randomIndex], array[currentIndex] ];
    }

    return array;
}
