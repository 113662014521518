import { ReactNode, useEffect } from "react";

const TITLE = "Karmis";

export function useTitle(title: string): void {
    useEffect(() => {
        if (title) {
            document.title = `${title} | ${TITLE}`;
        } else {
            document.title = TITLE;
        }
    }, [ title ]);
}

export function Loading(): JSX.Element {
    return <div className="loading">
        <div className="loader" />
    </div>;
}

type DivProps = {
    className?: string,
    children: ReactNode,
};

export function Div({ className, children }: DivProps): JSX.Element {
    return <div className={className}>{children}</div>;
}

Div.Flex = function Flex({ className, children }: DivProps): JSX.Element {
    return <div className={`flex ${className}`}>{children}</div>;
};
Div.FlexRow = function Flex({ className, children }: DivProps): JSX.Element {
    return <div className={`flex-row ${className}`}>{children}</div>;
};

Div.Grow1 = function Grow1({ className, children }: DivProps): JSX.Element {
    return <div className={`grow-1 ${className}`}>{children}</div>;
};
Div.Grow2 = function Grow2({ className, children }: DivProps): JSX.Element {
    return <div className={`grow-2 ${className}`}>{children}</div>;
};
Div.Grow3 = function Grow3({ className, children }: DivProps): JSX.Element {
    return <div className={`grow-3 ${className}`}>{children}</div>;
};
