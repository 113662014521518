import { Link } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";

// import { } from "../types";

import { version } from "../../package.json";
import { Div } from ".";
import Nav from "./Nav";

export default function Layout({ children }: { children: JSX.Element }): JSX.Element {

    return <Div.Flex className="layout">
        <header>
            <Link to="/" className="logo">
                <img src="/logo.svg" alt="Karmis logo" />
                Karmis
            </Link>
            <Nav />
        </header>
        <Div.Grow1 className="main-content">
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </Div.Grow1>
        <footer>
            <div>
                Karmis<br />
                <Link to="/kontakt">Kontakt</Link> <br />
            </div>
            <div className="rights">
                © 2021 Všechna práva vyhrazena Karmis <br />
                <span className="version">v{version}</span>
            </div>
        </footer>
    </Div.Flex>;
}
